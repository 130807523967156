<template>
  <div>
    <el-dialog
        title="编辑文章"
        :visible.sync="dialog"
        top="30px"
        @close="handleCloseDialog"
        width="80%">
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :span="6">
          <div class="card card-flush py-4" >
            <div class="card-body" style="padding-top: 20px">
              <label
                  class="fs-6 form-label fw-bolder text-dark required">
                文章标题
              </label>
              <input type="text" name="product_name" placeholder="文章标题" v-model="articleInfo.title"
                     class="form-control form-control-lg form-control-solid" >

              <label
                  style="margin-top: 20px"
                  class="fs-6 form-label fw-bolder text-dark required">
                可见性
              </label>
              <select
                  class="form-select form-select-solid" v-model="articleInfo.visibilityLevel">
                <option v-for="(item,index) in visibilityLevelList" :key="index" :value="item.value">{{item.desc}}</option>

              </select>

            </div>
          </div>

          <div class="card card-flush py-4" style="margin-top: 10px">
            <div class="card-body" style="padding-top: 20px">
              <label
                  style="margin-top: 20px"
                  class="fs-6 form-label fw-bolder text-dark required">
                关联套餐
              </label>
              <select
                  class="form-select form-select-solid" v-model="articleInfo.comboId">
                <option value="">不关联</option>
                <option v-for="(item,index) in goodsList" :key="index" :value="item.id">
                  {{item.title}}</option>
              </select>
              <label
                  style="margin-top: 20px"
                  class="fs-6 form-label fw-bolder text-dark required">
                关联门店
              </label>
              <select
                  class="form-select form-select-solid" v-model="articleInfo.storeId" @change="chooseThisStore">
                <option value=null>不关联</option>
                <option v-for="(item,index) in storeList" :key="index" :value="item.id">
                  {{item.storeName + "("+ item.branchName+")"}}</option>

              </select>
              <label
                  class="fs-6 form-label fw-bolder text-dark required">
                地址信息
              </label>
              <el-autocomplete
                  popper-class="my-autocomplete"
                  style="width: 100%;color: #4B5675;background: #F9F9F9;border: none"
                  v-model="articleInfo.addressDetail"
                  :fetch-suggestions="querySearchAsync"
                  @select="handleSelect"
                  placeholder="请输入地址信息"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ item.title }}</div>
                  <span class="addr" style=" font-size: 12px;
      color: #b4b4b4;">{{ item.address }}</span>
                </template>
              </el-autocomplete>
              <div id="editArticleContainerMaps" style="border-radius: 15px;margin-top: 10px"></div>
            </div>
          </div>

        </el-col>
        <el-col :span="18">
          <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="margin-right: 10px;border-radius: 15px">
            <div class="tab-content">
              <div id="kt_ecommerce_add_product_general" role="tab-panel"
                   class="tab-pane fade show active">
                <div class="d-flex flex-column gap-7 gap-lg-10">
                  <div class="card card-flush py-4"
                       style="border-radius: 0 0 15px 15px !important; ">
                    <div class="card-header">
                      <div class="card-title">
                        <h3 class="required">文章内容</h3>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <div class="mb-10 fv-row">
                        <el-input
                            type="textarea"
                            placeholder="请输入内容"
                            v-model="articleInfo.content"
                            maxlength="2000"
                            show-word-limit
                            :autosize="{ minRows: 6}"

                        >
                        </el-input>
                      </div>
                    </div>
                  </div>
                  <div class="card card-flush py-4"
                       style="border-radius: 0 0 15px 15px !important; ">
                    <div class="card-header">
                      <div class="card-title">
                        <h3 class="required">文章图片</h3>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <div class="mb-10 fv-row">
                        <div style="margin-left: 10px;">
                          <div style="display: flex;flex-wrap: wrap">
                            <div style="position: relative;margin-right: 10px;margin-bottom: 10px" v-for="(item,index) in articleInfo.articleFileList" :key="index"
                                 @click="openMaterial('headImg',index)">
                              <img style="border: 1px solid #e0e0e0;border-radius: 5px" :src="item.fullUrl" width="200px" alt="">
                              <div class="close-button-container" @click.stop="removeThisImg(index)">
                                <i class="el-icon-close"></i>
                              </div>
                            </div>
                            <div style="border: 1px solid #e0e0e0e0;border-radius: 5px;display: flex;flex-direction:column;align-items: center;background: #F7F7F7;width: 200px;height: 112px;justify-content: center;color: #C0C0BF"
                                 @click="openMaterial('headImg',articleInfo.articleFileList.length)">
                              <div style="font-size: 30px">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                              <div>
                                上传图片({{articleInfo.articleFileList.length}}/5)
                              </div>
                            </div>
                          </div>
                          <div>
                            <div style="font-size: 0.95rem;color: rgb(153, 161, 183);margin-top: 6px">
                              图片要求：宽度620px，大小不超过10M。
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

              </div>

            </div>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
      <el-button type="secondary" @click="dialog = false">取消</el-button>
      <el-button type="primary" @click="updateArticle" style="margin-left: 20px">保存</el-button>
    </span>
    </el-dialog>
    <material-component ref="MaterialComponent" @useImg="useImg"></material-component>
  </div>
</template>
<script>
import {mixins} from "@/views/Content/article/edit/mixins";
export default {
  mixins: [mixins],
}
</script>

<style scoped>
.close-button-container {
  position: absolute;
  top: 10px; /* 调整顶部距离 */
  right: 10px; /* 调整右侧距离 */
  width: 20px; /* 设置按钮宽度 */
  height: 20px; /* 设置按钮高度 */
  background-color: rgba(255, 255, 255, 0.8); /* 设置背景颜色和透明度 */
  border-radius: 50%; /* 圆角 */
  display: flex; /* 使用flex布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  cursor: pointer; /* 更改鼠标样式为手型 */
  transition: background-color 0.3s; /* 添加背景色过渡动画 */
}

.close-button-container:hover {
  background-color: rgba(255, 255, 255, 1); /* 鼠标悬停时改变背景色 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 添加阴影 */
}

/* 可选：自定义关闭图标样式 */
.el-icon-close {
  font-size: 18px; /* 调整图标大小 */
  color: #333; /* 调整图标颜色 */
}
</style>
